<template>
	<div class="col-lg-6">
		<div class="row">
			<div class="col-xl-12 col-sm-12">
				<div class="card overflow-hidden">
					<div class="card-heading bg-light">
						<h3 class="card-title text-uppercase">Informasi Dinas Kesehatan</h3>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="insertData()" class="form-horizontal">
							<div class="form-group row mt-3">
								<label class="col-md-3 control-label">Kode</label>
								<div class="col-md-9">
									<input type="text" v-model="info.Kode" name="code" class="form-control">
								</div>
							</div>
							<div class="form-group row mt-3">
								<label class="col-md-3 control-label">Nama Dinas</label>
								<div class="col-md-9">
									<input type="text" v-model="info.NamaDinas" name="name" class="form-control">
								</div>
							</div>
							<div class="form-group row mt-3">
								<label class="col-md-3 control-label">Kabupaten/Kota</label>
								<div class="col-md-9">
									<input type="text" v-model="info.Kabupaten" name="regencry" class="form-control">
								</div>
							</div>
							<div class="form-group row mt-3">
								<label class="col-md-3 control-label">Provinsi</label>
								<div class="col-md-9">
									<input type="text" v-model="info.Propinsi" name="province" class="form-control">
								</div>
							</div>
							<div class="form-group row mt-3">
								<label class="col-md-3 control-label">Alamat</label>
								<div class="col-md-9">
									<input type="text" v-model="info.Alamat" name="addressphone" class="form-control">
								</div>
							</div>
							<div class="form-group row mt-3">
								<label class="col-md-3 control-label">Telepon</label>
								<div class="col-md-9">
									<input type="text" v-model="info.No_tlpn" name="phone" class="form-control">
								</div>
							</div>
							<div class="form-group row mt-3">
								<label class="col-md-3 control-label">Kepala Dinas</label>
								<div class="col-md-9">
									<input type="text" v-model="info.Kepala" name="hod" class="form-control">
								</div>
							</div>
							<div class="form-group row mt-3">
								<label class="col-md-3 control-label">NIP</label>
								<div class="col-md-9">
									<input type="text" v-model="info.NIP" name="identity" class="form-control">
								</div>
							</div>
							<div class="row">
								<div class="col-sm-12">
									<button type="button" class="btn btn-custom m-t-10 float-right" disabled>Batal</button>
									<button type="submit" class="btn btn-custom m-t-10 float-right mr-1" disabled>Simpan</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			<!-- end col -->
		</div>
		<!--end row -->
	</div>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";

export default {

	name: 'DinkesComponent',
	setup() {
        const toast = useToast();
        return {
            toast
        }
    },
	data() {
		return {
			info:{}
		};
	},
	methods: {
		async getData() {
			await axios.get(`dasar/infoDinkes`).then(response => {
				this.info = response.data.content.dinkes;
			})
			.catch(e => {
				this.toast.error(e.response.data.errors.message.description);
			});
		},
		async insertData() {
			console.log(this.info);
		}
	},
	created() {
		this.getData();
	}
};
</script>
